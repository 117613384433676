import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getCurrentUser } from "../../services/authService";

const CoachRoute = () => {
  const user = getCurrentUser();

  // Define allowed roles
  const allowedRoles = ["coach", "superAdmin"];

  // Check if user exists and has any of the allowed roles
  if (!user || !user.roles.some((role) => allowedRoles.includes(role))) {
    // Redirect to home page if not authorized
    return <Navigate to='/' replace />;
  }

  // Allow access to panel routes
  return <Outlet />;
};

export default CoachRoute;
