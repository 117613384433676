import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getCurrentUser } from "../../services/authService";

const SuperAdminRoute = () => {
  const user = getCurrentUser();

  // Check if user exists and has superadmin role
  if (!user || !user.roles.includes("superAdmin")) {
    // Redirect to home page if not authorized
    return <Navigate to='/' replace />;
  }

  // Allow access to superadmin routes
  return <Outlet />;
};

export default SuperAdminRoute;
